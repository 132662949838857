import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';

export default function LabelControl (props) {
    return (
      <Box py={2}>
        <Grid container display="flex" alignItems="center">
          <Grid style={{width: "100%", maxWidth: "170px"}} item>
            <Box width="170px">
              <Typography style={{fontWeight: 600}}>{props.label}</Typography>
            </Box>
          </Grid>
          <Grid style={{width: "100%", maxWidth: "300px"}} item>
              {props.children}
          </Grid>
        </Grid>
      </Box>
    )
  }