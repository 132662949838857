
const Months = ["January", "February", "March", "April", "May", "June", 
"July", "August", "September", "October", "November", "December"];

export default class ErrorService {
    static getError (errCode, dates) {
        switch (errCode) {
            case 'OverlappingEntries':
                dates = dates ? dates : [];
                let formattedDates = dates.map(date => {
                    const dateObj = new Date(date);
                    return ' ' + dateObj.getDate() + ' ' + Months[dateObj.getMonth()];
                });
                formattedDates.join(',');
                return `Overlapping entries observed for ${formattedDates}, please rectify and try again`;
            case 'InvalidCredentials':
                return 'Invalid user credentials, please try again';
            case 'DefaultReportErr':
                return 'Unable to generate report, please log an issue in the issue board';
            case 'AuthenticationFailure':
                    return "You are not authorized to download/submit the report for the selected user.";
            case 'AuthorizationError':
                    return "User not authorized to upload reports for any other month rather than previous month";
            case 'DownloadReportFailure':
                    return "Unable to download the report.";
            case 'SubmitReportFailure':
            case 'GoogleAPIError':
                    return "Unable to submit the report.";

            default:
                return 'Something went wrong, please log an issue in the issue board';
        }
    }
}