import React from 'react';
import PrivateRoute from './common/private-route';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container, Box, Paper } from '@material-ui/core';
import Home from './home/home';
import Login from './login/login';
import ComproLogo from './assets/comprologo.png';
import Dialog from './common/dialog';

function App() {
  const dialogRef = React.useRef();

  const openDialog = (message, mode, confirmationHandler) => {
    dialogRef.current.open(message, mode, confirmationHandler);
  }

  return (
    <Container maxWidth="lg" className="App">
      <Box my={5}>
        <Paper>
          <Box py={4} pl={8} pr={8} position={"relative"}>
            <Box p={1} position={"absolute"} right={"58px"}>
              <img alt="Compro Logo" src={ComproLogo} />
            </Box>
            <Router>
              <div>
                <PrivateRoute exact path="/" component={(props) => <Home {...props} openDialog={openDialog}/>} />
                <Route path="/login" component={(props) => <Login {...props} openDialog={openDialog}/>} />
              </div>
            </Router>
          </Box>
          <Dialog ref={dialogRef} />
        </Paper>
      </Box>
    </Container>
  );
}

export default App;
