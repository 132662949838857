import React, { useState } from 'react';
import { 
  Select, 
  Box,  
  Typography,
  MenuItem,
  Button,
  makeStyles
} from '@material-ui/core';
import AutoComplete from '../common/autocomplete';
import DataService from '../data.service';
import Loader from '../common/loader';
import LabelControl from '../common/label-control';
import ErrorService from '../error.service';
import { DialogModes } from '../common/dialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";


const ReportTypes = [
  {displayValue: 'Monthly Time Statement', value: 'monthly-time-statement'},
  {displayValue: 'Activity and Progress Report', value: 'activity-and-progress'}
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  fullWidth: {
    width: "100%"
  },
  noTextTransform: {
    textTransform: "none"
  }
}));


function Home (props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [workspaceId, setWorkspaceId] = React.useState();
  const [workspaceList, setWorkspaceList] = React.useState([]);
  const [reportType, setReportType] = React.useState('monthly-time-statement');
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [employeeList, setEmployeeList] = React.useState([]);
  const classes = useStyles();
  const [folderPath, setFolderPath] = React.useState();
  const date = new Date();
  date.setMonth(date.getMonth() -1);
  const previousMonthIdx = date.getMonth();
  const previousMonthYear = date.getFullYear();
  const [selectedDate, handleDateChange] = useState(new Date(previousMonthYear, previousMonthIdx));
  const maxDateLimit = new Date();

  const submitReportsConfirmation = <><p>Are you sure you want to upload <b>Monthly Time Statement</b> and <b>Activity and Progress Report</b> for <b>{selectedEmployee && selectedEmployee.name}</b> to centralized report folder?</p><Typography variant="caption"><b>Note: Make sure you have downloaded and verified both the reports.</b></Typography></>;

  const onError = (message) => {
    props.openDialog(message);
  }

  React.useEffect(() => {
    setIsLoading(true);
    DataService.getAllWorkspaces().then(workspaces => {
      setWorkspaceList(workspaces);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      setEmployeeList([]);
      onError(ErrorService.getError(err.ERRCODE));
    });
  }, []);

  const onWorkspaceChange = (e) => {
    const _workspaceId = e.target.value;
    setWorkspaceId(_workspaceId);
    setSelectedEmployee(null);
    setIsLoading(true);
    DataService.getAllUsers(_workspaceId).then(users => {
      setEmployeeList(users);
      selectUser(users);
      setIsLoading(false);
    }).catch ((err) => {
      setIsLoading(false);
      setEmployeeList([]);
      onError(ErrorService.getError(err.ERRCODE));
    });
  };

  const selectUser = (users)=>{
    let loggedInEmail = DataService.getUserProfile().email;
    let loggedInUserData = users.find((userData)=> {return loggedInEmail == userData.email})
    loggedInUserData && setSelectedEmployee(loggedInUserData);
  }

  const downloadReport = () => {
    if (selectedEmployee) {
      switch(reportType) {
        case 'monthly-time-statement': 
          setIsLoading(true);
          DataService.getMonthlyTimeStatement(workspaceId, selectedEmployee.email, selectedDate.getMonth(), selectedDate.getFullYear()).then(() => {
            setIsLoading(false);
          }).catch (err => {
            setIsLoading(false);
            onError(ErrorService.getError(err.ERRCODE || 'DownloadReportFailure' , err.data ? err.data.dates: []));
          });
          break;
        
        case 'activity-and-progress': 
          setIsLoading(true);
          DataService.getActivityAndProgressReport(workspaceId, selectedEmployee.email, selectedDate.getMonth(), selectedDate.getFullYear()).then(data => {
            setIsLoading(false);
          }).catch (err => {
            setIsLoading(false);
            onError(ErrorService.getError(err.ERRCODE || 'DownloadReportFailure', err.data ? err.data.dates: []));
          });
          break;

        default:
          break;
      }
    }
  }

  const onSubmit = () => {
    props.openDialog(submitReportsConfirmation, DialogModes.CONFIRMATION, uploadReports);
  }

  const uploadReports = () => {
    if (selectedEmployee) {
      setIsLoading(true);
      DataService.submitReport(workspaceId,selectedEmployee.email, selectedDate.getMonth(), selectedDate.getFullYear()).then((response) => {
        setIsLoading(false);
        setFolderPath(response.reportsFolderPath);
        let successText = <p>Reports successfully uploaded at <a style={{"overflow-wrap":"break-word"}} href={response.reportsFolderPath} target="_blank">{response.reportsFolderPath}</a></p>;
        props.openDialog(successText, DialogModes.SUCCESS);
      }).catch (err => {
        setIsLoading(false);
        onError(ErrorService.getError(err.ERRCODE || 'SubmitReportFailure', err.data ? err.data.dates: []));
      });
    }
  }

  return (
    <Box position="relative">
      <Box marginBottom={"50px"}>
        <Typography variant="h4" color="primary">
          Time Reporting Tool
        </Typography>
      </Box>
      <Box maxWidth={"500px"} margin="auto">
        <LabelControl label="Workspace:">
          <Select
            fullWidth
            value={workspaceId}
            onChange={onWorkspaceChange}
          >
            {workspaceList.map(workspace => <MenuItem key={workspace.id} value={workspace.id}>{workspace.label}</MenuItem>)}
          </Select>
        </LabelControl>
        <LabelControl label="Report:">
          <Select
            fullWidth
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            {ReportTypes.map(type => <MenuItem key={type.value} value={type.value}>{type.displayValue}</MenuItem>)}
          </Select>
        </LabelControl>
        <LabelControl label="Employee: ">
          <AutoComplete onValueChange={setSelectedEmployee} value={selectedEmployee} list={employeeList} />
        </LabelControl>
        <LabelControl label="Year and Month:">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={classes.fullWidth}
              autoOk
              variant="inline"
              openTo="month"
              views={["year", "month"]}
              minDate={new Date("2020-03-01")}
              maxDate={maxDateLimit}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </LabelControl>

        <Box mt={10} textAlign="center" p={2} className={classes.root}>
          <Button
            variant="contained"
            disabled={selectedEmployee === undefined || selectedEmployee === null}
            color="primary"
            onClick={downloadReport}
          >
            Download
          </Button>
          <Button
              variant="contained"
              disabled={selectedEmployee === undefined || selectedEmployee === null}
              color="primary"
              onClick={onSubmit}
            >
              Upload Reports
          </Button>
        </Box>
      </Box>
      <Loader relativeParent showLoader={isLoading} />
    </Box>
  );
}
export default Home;
