import axios from 'axios';
const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    let user = sessionStorage.getItem("user");
    if(user) {
        config['headers'] = {
            authorization: JSON.parse(user).tokenId
        }
    }    
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default class DataService {
    static URLS = {
        base: "api",
        reports: "api/reports",
        login: "api/auth/login"
    };
    static api = axiosInstance;
    
    static getAllUsers (workspaceId) {
        return DataService.api.get(`${DataService.URLS.base}/workspace/${workspaceId}/users`).then(response => {
            return response.data;
        }).catch(err => {
            throw err.response.data;
        })
    }

    static getAllWorkspaces () {
        return DataService.api.get(`${DataService.URLS.base}/workspaces`).then(response => {
            return response.data;
        }).catch(err => {
            throw err.response.data;
        })
    }

    static getMonthlyTimeStatement (workspaceId, userId, month, year) {
        return DataService.api.get(`${DataService.URLS.reports}/workspace/${workspaceId}/user/${userId}/detailed-time-summary/excel-download`,
        {params: {month, year}, responseType: 'blob'}
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'monthly-time-statement.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            return new Promise((resolve, reject) => {
                err.response.data.text().then (val => {
                    reject(JSON.parse(val));
                });
            });
        });
    }

    static getActivityAndProgressReport (workspaceId, userId, month, year) {
        return DataService.api.get(`${DataService.URLS.reports}/workspace/${workspaceId}/user/${userId}/monthly-summary/excel-download`,
        {params: {month, year}, responseType: 'blob'}
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'activity-and-progress.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch(err => {
            return new Promise((resolve, reject) => {
                err.response.data.text().then (val => {
                    reject(JSON.parse(val));
                });
            });
        })
    }

    static logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('user');
    }

    static onLoginSuccessful(userData) {
        sessionStorage.setItem('user', JSON.stringify(userData));
    }

    static getUserProfile(){
        let userData =  JSON.parse(sessionStorage.getItem("user")).profileObj;
        return userData;
    }

    static submitReport(workspaceId, userId, month, year) {
        return DataService.api.post(`${DataService.URLS.reports}/workspace/${workspaceId}/user/${userId}/submit`, {
                monthIndex: month,
                year: year
            }).then( response => {
            return response.data;
        }).catch(err => {
            return new Promise((resolve, reject) => {
                reject(err.response.data)
            });
        })
    }
}