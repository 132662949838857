import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
    autocompleteInput: {
        padding: "1px !important"
    }
}));
export default function AutoComplete (props) {
    const classes = useStyles();
    return (
        <Autocomplete
            fullWidth
            classes={{input: classes.autocompleteInput}}
            value={props.value}
            options={props.list}
            onChange={(e, value) => props.onValueChange(value)}
            getOptionLabel={(option) => option ? option.name: ''}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
    );
}