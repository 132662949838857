import React from 'react';
import { Box, Button, TextField, Typography, InputAdornment, IconButton } from '@material-ui/core';
import DataService from '../data.service';
import Loader from '../common/loader';
import LabelControl from '../common/label-control';
import ErrorService from '../error.service';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { GoogleLogin } from 'react-google-login';
import { makeStyles } from '@material-ui/core/styles';
import { ClientId } from "../constant";

class Login extends React.Component {

    constructor(props) {
        super(props);
        DataService.logout();
        this.responseGoogle = this.responseGoogle.bind(this);
    }

    responseGoogle = (response) => {
        DataService.onLoginSuccessful(response);
        this.props.history.replace({
                pathname: "/"
        })
    }

    failureResponseGoogle = (response) => {
        console.log(response);
    }

    render(props) {
        return (
            <Box position="relative">
                <Box marginBottom="80px">
                    <Typography variant="h4" color="primary">
                        Time Reporting Tool
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h5">
                        Lets get started
                    </Typography>
                    <Typography variant="h6" color={"textSecondary"}>
                        Sign in to view your time reporting
                    </Typography>
                </Box>
                <Box marginTop={"40px"} marginBottom={"40px"}>
                    <GoogleLogin
                        clientId={ClientId} 
                        buttonText="Sign in with Compro Google Account"
                        onSuccess={this.responseGoogle}
                        onFailure={this.failureResponseGoogle}
                        theme={"dark"}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        prompt={"select_account"}
                    />
                </Box>
            </Box>
        );
    }
}

export default Login;