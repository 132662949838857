import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogModes = {
  ERROR: "Error",
  CONFIRMATION: "Confirmation",
  SUCCESS: "Success"
}

const ErrorDialog =  React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState('No message');
  const [title, setTitle] = React.useState(DialogModes.ERROR);
  const [mode, setMode] = React.useState(DialogModes.ERROR);
  const [callbackHandlers, setCallbackHandlers] = React.useState({});

  React.useImperativeHandle(ref, () => ({
    open: handleClickOpen,
  }));

  const handleClickOpen = (message, mode=DialogModes.ERROR, callback) => {
    setText(message);
    setMode(mode);
    setTitle(mode);
    setCallbackHandlers({confirmation: callback});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    callbackHandlers.confirmation && callbackHandlers.confirmation();
  };

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          <DialogContentText id="alert-dialog-slide-description">
              {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { mode === DialogModes.CONFIRMATION && <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          }
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
  );
});

export default ErrorDialog;